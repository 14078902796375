// eslint-disable-next-line import/no-cycle
import Auth from '../authorization/Auth';
import config from '../config';
import {
  formatMarkdownAddBody, formatPromotionAddBody, formatPartnerPromotionAddBody
} from '../utils/formatting';

import {
  get, patch, post, put, postPartner, getPartner, patchPartner, postCsv
} from './reusable';

const {
  HOST,
  HOST_PARTNER,
  HOST_DELEDGE,
  HOST_STORE_LOCATIONS,
  HOST_STORE_VIEWS,
  RESOURCE_AUTH,
  RESOURCE_MARKDOWNS,
  RESOURCE_PROMOTIONS,
  RESOURCE_SCS,
  RESOURCE_STOCK_ON_HAND,
  RESOURCE_STORE_LOCATIONS,
  RESOURCE_STORE_VIEWS,
  RESOURCE_PARTNER_PROMOTIONS,
  RESOURCE_CSV_UPLOAD,
  oktaRedirectURI,
  oktaClientID,
  oktaAegisHost,
  oktaTokenResource
} = config();

export const OktaException = (message) => ({ message, name: 'OktaException' });

export const getPromotion = async (promotionId) => get(await Auth.confirmAuth(), HOST, `${RESOURCE_PROMOTIONS}/${promotionId}`);

export const getPartnerPromotion = async (promotionId) => getPartner(await Auth.confirmAuth(), HOST_PARTNER, `${RESOURCE_PARTNER_PROMOTIONS}/${promotionId}`);

export const getPromotions = async (storeId = Auth.getStoreId()) => get(await Auth.confirmAuth(), HOST, RESOURCE_PROMOTIONS, `?filter=storeId(${storeId})`);

export const getPartnerPromotions = async (partnerName) => getPartner(await Auth.confirmAuth(), HOST_PARTNER, RESOURCE_PARTNER_PROMOTIONS, `?filter=partnerName(${partnerName})`);

export const patchPromotion = async (promotionId, body) => patch(await Auth.confirmAuth(), HOST, `${RESOURCE_PROMOTIONS}/${promotionId}`, body);

export const patchPartnerPromotion = async (promotionId, body) => patchPartner(await Auth.confirmAuth(), HOST_PARTNER, `${RESOURCE_PARTNER_PROMOTIONS}/${promotionId}`, body);

export const postPromotion = async (body) => post(await Auth.confirmAuth(), HOST, RESOURCE_PROMOTIONS, formatPromotionAddBody(body));

export const postPartnerPromotion = async (body) => postPartner(await Auth.confirmAuth(), HOST_PARTNER, RESOURCE_PARTNER_PROMOTIONS, formatPartnerPromotionAddBody(body));

export const putPromotion = async (body) => put(await Auth.confirmAuth(), HOST, `${RESOURCE_PROMOTIONS}/${body.id}`, body);

export const getStockOnHand = async (storeId = Auth.getStoreId(), productType, { minimumQuantity, maximumQuantity }) => get(await Auth.confirmAuth(), HOST, RESOURCE_STOCK_ON_HAND, `?filter=storeId(${storeId})&filter=divisionId(${productType})${minimumQuantity ? `&filter=minimumQuantity(${minimumQuantity})` : ''}${maximumQuantity ? `&filter=maximumQuantity(${maximumQuantity})` : ''}`);

export const getMarkdowns = async (storeId = Auth.getStoreId()) => get(await Auth.confirmAuth(), HOST, RESOURCE_MARKDOWNS, `?filter=storeId(${storeId})`);

export const postMarkdown = async (body) => post(await Auth.confirmAuth(), HOST, RESOURCE_MARKDOWNS, formatMarkdownAddBody(body));

export const postCsvUpload = async (data, uploadId, batchNo, isLastBatch) => postCsv(await Auth.confirmAuth(), HOST, RESOURCE_CSV_UPLOAD, data, uploadId, batchNo, isLastBatch);

/* Authorization */
export const authUser = async (loginInformation) => {
  const body = {
    loginInformation: {
      athleteNumber: loginInformation.athleteNumber,
      athletePassword: loginInformation.athletePassword,
      selectedCountry: loginInformation.selectedCountry,
      selectedStore: loginInformation.selectedStore,
    },
    refresh: false,
  };
  return post(null, HOST, RESOURCE_AUTH, body);
};

export const reAuthUser = async (accessToken, refreshToken) => {
  const body = {
    loginInformation: {
      access_token: accessToken,
      refresh_token: refreshToken,
    },
    refresh: true,
  };

  return post(null, HOST, RESOURCE_AUTH, body);
};

/* Store Locations */
const fetchWithTimeout = async (resource, options) => {
  const { timeout = 8000 } = options;
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);

  return response;
};

const formatStoreData = async (store) => ({
  currency: store.currencies[0],
  id: store.id,
  locale: store.locale,
  region: store.region,
  timezone: store.timezone
});

const formatCountries = (storesArr) => {
  const arr = storesArr.reduce((prevItem, currItem) => {
    const key = currItem.address.country;
    if (prevItem[key]) {
      // eslint-disable-next-line fp/no-mutating-methods
      prevItem[key].push(currItem.id);
    } else {
      // eslint-disable-next-line fp/no-mutation
      prevItem[key] = [currItem.id];
    }
    return prevItem;
  }, {});
  return arr;
};

// SCS promote app config
const SCS_PROMOTE_KEYS = ['promote.createMarkdowns', 'promote.createPromotions', 'promote.isStyleUploadEnabled', 'promote.allowPromoteApp'];
export const getPromoteConfig = async (country, storeNumber) => fetchWithTimeout(
  `${HOST_DELEDGE}${RESOURCE_SCS}/${country}-${storeNumber}?filter=key(${SCS_PROMOTE_KEYS.toString()})`,
  {
    headers: { Authorization: `Bearer ${await Auth.confirmAuth()}` },
    method: 'GET'
  }
)
  .then(async (res) => {
    const scsData = await res.json();
    const promoteObject = scsData.objects.find((obj) => obj.key === 'promote.allowPromoteApp');
    if (!promoteObject) {
      return { data: null, errorMessage: 'Promote App is not available in this store' };
    }
    if (promoteObject.value === false && promoteObject.scopeType === 'STORE') {
      return { data: null, errorMessage: 'Promote App is not available in this store' };
    }
    return {
      data: scsData.objects.reduce((prev, curr) => {
        prev[curr.key.split('.')[1]] = curr.value;
        return prev;
      }, {}),
      errorMessage: null,
    };
  })
  .catch((err) => {
    console.error('Error in getPromoteConfig:', err);
    return { data: null, errorMessage: 'Failed to fetch configuration.' };
  });

export const getStore = async (country, storeNumber) => {
  const queryString = `?search=(address.country==${country} and storeNumber==${storeNumber})&fields=address,currencies,id,locale,name,phone,region,timezone`;
  return fetch(`${HOST_STORE_LOCATIONS}${RESOURCE_STORE_LOCATIONS}${queryString}`, {
    method: 'GET'
  }).then(async (res) => {
    if (!res.ok) {
      // eslint-disable-next-line fp/no-throw
      throw await res.json();
    }
    return formatStoreData((await res.json()).objects[0]);
  });
};

export const postCodeToOkta = async () => {
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search.slice(1));
  const code = searchParams.get('code');
  if (!code) {
    const error = searchParams.get('error') || 'Unauthorized';
    // eslint-disable-next-line fp/no-throw
    throw new OktaException(error);
  }
  const codeVerifier = localStorage.getItem('code_verifier');
  const params = new URLSearchParams();

  params.set('code', code);
  params.set('code_verifier', codeVerifier);
  params.set('grant_type', 'authorization_code');
  params.set('redirect_uri', oktaRedirectURI);
  params.set('client_id', oktaClientID);

  const options = {
    body: params,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  };
  const uri = oktaAegisHost + oktaTokenResource;

  return fetch(uri, options).then(async (res) => {
    if (res.status !== 200) {
      // eslint-disable-next-line camelcase,fp/no-throw
      throw Error(res.reasons?.error_description);
    }
    return res.json();
  });
};

/* Store Views */
export const storeViewsByPartner = async (partner) => {
  const options = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/x-www-form-urlencoded',
      'nike-api-caller-id': 'com.nike:test-caller-id',
    },
    json: true,
    method: 'GET',
    resolveWithFullResponse: true
  };
  try {
    const res = await fetch(`${HOST_STORE_VIEWS}${RESOURCE_STORE_VIEWS}?search=(company==${partner})&fields=id,address.country&count=1000`, options);
    const result = await res.json();
    return formatCountries(result.objects);
  } catch (err) {
    // eslint-disable-next-line fp/no-throw
    throw await err.json();
  }
};
