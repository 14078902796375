import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Auth from '../../authorization/Auth';
import OktaAuth from '../../authorization/OktaAuth/index';
import { DEFAULT_LOCALE, scsKeys } from '../../constants';
import { getIsPartnerLogin } from '../../redux/selectors';
import {
  setAllowPromoteApp, setCountry, setIsMarkdownEnabled, setLocale, setIsCreatePromotionEnabled, setIsCsvUploadEnabled
} from '../../redux/store';
import { getStore, getPromoteConfig } from '../../service-calls';
import { isAllTranslationsPresent } from '../../utils/translationsUtils';

import LoginForm from './LoginForm';

const LogIn = () => {
  const [auth, setAuth] = useState(false);
  const [storeCurrency, setStoreCurrency] = useState('');
  const [storeId, setStoreId] = useState('');
  const [storeLocale, setStoreLocale] = useState('');
  const [storeTimezone, setStoreTimezone] = useState('');
  const [error, setError] = useState('');
  const [isFetching, setIsFetching] = useState(false);
  const [athleteNumber, setAthleteNumber] = useState('');
  const [athletePassword, setAthletePassword] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const dispatch = useDispatch();

  const isPartnerLogin = useSelector(getIsPartnerLogin);

  useEffect(() => {
    dispatch(setCountry(selectedCountry));
    // set a cleanup function
    return () => {
      dispatch(setCountry(selectedCountry));
    };
  }, [selectedCountry, dispatch]);

  const formIsNotFilled = () => !(
    athleteNumber.length
    && athletePassword.length
    && selectedCountry?.length
    && selectedStore.length
  );

  const setLocalStorage = (currency, id, locale, region, timezone) => {
    localStorage.setItem('country', selectedCountry);
    localStorage.setItem('storeCurrency', currency);
    localStorage.setItem('storeId', id);
    localStorage.setItem('storeLocale', locale);
    localStorage.setItem('storeNumber', selectedStore);
    localStorage.setItem('storeRegion', region);
    localStorage.setItem('storeTimezone', timezone);
  };

  const checkTranslations = async (locale) => {
    const isTranslationsAvailable = storeLocale === DEFAULT_LOCALE || isAllTranslationsPresent(locale);
    localStorage.setItem('isTranslationsAvailable', isTranslationsAvailable);
  };

  const onSubmit = async () => {
    setIsFetching(true);
    setError('');

    try {
      await Auth.login({
        athleteNumber,
        athletePassword,
        selectedCountry,
        selectedStore,
      });
      const {
        currency, id, locale, region, timezone
      } = await getStore(selectedCountry, selectedStore);
      setLocalStorage(currency, id, locale, region, timezone);

      const { data, errorMessage } = await getPromoteConfig(selectedCountry, selectedStore);
      if (errorMessage) {
        localStorage.setItem(scsKeys.allowPromoteApp, 'false'); // Set local storage key to false
        throw new Error(errorMessage);
      }

      Object.keys(data).forEach((key) => {
        localStorage.setItem(`scs.${key}`, data[key]);
      });

      setStoreCurrency(currency);
      setStoreId(id);
      setStoreLocale(locale);
      dispatch(setLocale(locale));
      setStoreTimezone(timezone);
      await checkTranslations(locale);
      // Set the markdown and promotion flags from local storage on login
      dispatch(setIsMarkdownEnabled(localStorage.getItem(scsKeys.createMarkdowns) === 'true'));
      dispatch(setIsCreatePromotionEnabled(localStorage.getItem(scsKeys.createPromotions) === 'true'));
      dispatch(setIsCsvUploadEnabled(localStorage.getItem(scsKeys.isStyleUploadEnabled) === 'true'));
      dispatch(setAllowPromoteApp(localStorage.getItem(scsKeys.allowPromoteApp) === 'true'));
      setAuth(true);
    } catch (err) {
      setError(err.message || 'Sign in Failed');
      localStorage.clear();
      setAuth(false);
    } finally {
      setIsFetching(false);
    }
  };

  return auth
    ? (
      <Redirect
        to={{
          pathname: '/promotions',
          state: {
            country: selectedCountry,
            storeCurrency,
            storeId,
            storeLocale,
            storeNumber: selectedStore,
            storeTimezone,
          },
        }}
      />
    )
    : (
      (isPartnerLogin && <OktaAuth />) || (
      <LoginForm
        disableSubmit={formIsNotFilled()}
        errorMessage={error}
        fetching={isFetching}
        loginInformation={{
          athleteNumber, athletePassword, selectedCountry, selectedStore
        }}
        setAthleteNumber={setAthleteNumber}
        setAthletePassword={setAthletePassword}
        setSelectedCountry={setSelectedCountry}
        setSelectedStore={setSelectedStore}
        onSubmit={onSubmit}
      />
      )
    );
};

export default LogIn;
