export const DEFAULT_LOCALE = 'en-US';
export const PERCENT_90 = 900;
export const PARTNER_MISSING_ACC_ACCESS_ERR = 'You are currently not a member of Promote App - Partner AD Group. ** Please visit ID Locker to get AD Group membership of the partner group you are attempting to access. ** Example AD Group: App.Promote.Partner.<partnername>.Users';
export const PARTNER_MULTI_ACC_ACCESS_ERR = 'You are currently a member of more than one Promote App - Partner AD Group. ** Please visit ID Locker to remove AD Group membership for all groups with the exception of the partner group you are attempting to access. ** Example AD Group: App.Promote.Partner.<partnername>.Users';
export const LEVEL_40 = [
  'Admin', 'AssistantManagerBOH', 'AssistantManagerFOH',
  'KeyHolder',
  'ManagerBOH', 'ManagerFOH',
  'SupportBOH', 'SupportFOH'
];
export const LEVEL_20 = [
  ...LEVEL_40,
  'Cashier',
  'CustomerService',
  'InventoryAdjuster',
  'ShippingReceivingAssociate',
];
export const PARTNER_COUNTRY_FETCH_ERR = 'Failed to fetch partner countries.';
export const PARTNER_NO_ASSOCIATED_PROMOTIONS_ERR = 'There are no associated promotions.';
export const DROPDOWN_OPTION_ALL = { label: 'All', value: '' };
export const scsKeys = {
  allowPromoteApp: 'scs.allowPromoteApp',
  createMarkdowns: 'scs.createMarkdowns',
  createPromotions: 'scs.createPromotions',
  isStyleUploadEnabled: 'scs.isStyleUploadEnabled',
};

export const CSV_UPLOAD = {
  BATCH_INTERVAL_MS: 1000,
  BATCH_SIZE: 100,
  MAX_ALLOWED_ROWS: 1000,
  STATUS_FAILURE: 'RL_Failure',
  STATUS_PROCESSING: 'RL_Waiting',
  STATUS_SUCCESS: 'RL_Success',
  WARNING_PAGE_LEAVE: 'The Upload is in progress. By leaving this page you would be aborting the upload.\n\nNote: Some of the StyleColors would have been already added to the promotions ?',
};

export const defaultPromotions = ['MENS CLEARANCE', 'WOMENS CLEARANCE', 'KIDS CLEARANCE', 'MENS BACKWALL', 'WOMENS BACKWALL', 'KIDS BACKWALL'];
