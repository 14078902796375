import { DEFAULT_LOCALE } from '../constants';

export const getLocale = (state) => state.storeLocale;
export const getLocaleToggleStatus = (state) => state.localeToggle;
export const getIsPartnerLogin = (state) => state.isPartnerLogin;
export const getLocaleForToggleButton = (state) => (getLocaleToggleStatus(state) ? getLocale(state) : DEFAULT_LOCALE);
export const getLocaleForTranslation = (state) => (getLocaleToggleStatus(state) ? DEFAULT_LOCALE : getLocale(state));
export const getPartnerStores = (state) => state.partnerStores;
export const getIsMarkdownEnabled = (state) => state.markdownEnabled;
export const getAllowPromoteApp = (state) => state.allowPromoteApp;
export const getIsCreatePromotionEnabled = (state) => state.createPromotionEnabled;
export const getIsCsvUploadEnabled = (state) => state.isStyleUploadEnabled;
